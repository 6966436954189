<template>

  <div>
    <b-row>
      <b-col>
        <b-form-group label="Namn/Företag" label-for="person-name">
            <b-form-input autocomplete="off"
            id="person-name"
            v-model="form.name"
            placeholder=""
            :state="validName"
            />
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col>

        <div id="show-earlier" class="d-flex align-items-center mr-12 mt-8 mb-8">
          <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
            <input
              type="checkbox"
              name=""
              v-model="is_org"
              @click="is_org = !is_org"
            />
            <span></span>
          </label>
          <span class="ml-3 cursor-pointer">Är företag</span>
        </div>


        <b-form-group v-if="!is_org" label="Personnummer" label-for="person-personnr">
            <b-form-input autocomplete="off"
            id="person-personnr"
            v-model="form.personnr"
            placeholder=""
            :state="validPersonnr"
            />
            <b-form-text>ÅÅÅÅMMDD1234</b-form-text>
        </b-form-group>


        <b-form-group v-if="is_org" label="Organisationsnummer" label-for="person-personnr">
            <b-form-input autocomplete="off"
            id="person-personnr"
            v-model="form.org_number"
            placeholder=""
            :state="validOrgNumber"
            />
            <b-form-text>XXYYZZ1234</b-form-text>
        </b-form-group>


      </b-col>
    </b-row>

    <b-row>
      <b-col>

        <b-form-group :label="$t('MEMBER.EMAIL')" label-for="person-email">
            <b-form-input autocomplete="off"
            id="person-email"
            v-model="form.email"
            placeholder=""
            :state="validEmail"
            />
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col>


        <b-form-group label="Telefon" label-for="person-phone">
            <b-form-input autocomplete="off"
            id="person-phone"
            v-model="form.phone"
            placeholder=""
            :state="validPhone"
            />
        </b-form-group>

      </b-col>
    </b-row>

    <b-row>
      <b-col>



        <b-form-group label="Adress" label-for="person-address">
            <b-form-input autocomplete="off"
            id="person-address"
            v-model="form.address"
            :state="validAddress"
            placeholder=""
            />
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12" lg="4">
        <b-form-group label="Postnr" label-for="person-zipcode">
            <b-form-input autocomplete="off"
            id="person-address"
            v-model="form.zipcode"
            :state="validZipcode"
            placeholder=""
            />
        </b-form-group>

      </b-col>
      <b-col cols="12" lg="8">
        <b-form-group label="Ort" label-for="person-post">
            <b-form-input autocomplete="off"
            id="person-post"
            v-model="form.post"
            :state="validPost"
            placeholder=""
            />
        </b-form-group>
      </b-col>
    </b-row>

    <b-row v-if="!allIsValid">
      <b-col>
        <p style="margin-top: 8px; color: red;">Fyll i uppgifterna innan du fortsätter</p>
      </b-col>
    </b-row>
  </div>

</template>


<style lang="scss" scoped>

.form-group {
  margin-bottom: 6px;
}

</style>

<script>

import { validate_email } from '@/core/services/email';
import validate_pers_number from '@/core/services/personnr';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'invoice-person-item',
  props: [],
  emits: ['change', 'deleteMemberClicked'],
  components: {

  },
  mixins: [ toasts ],
  computed: {
    validName() {
      if (!this.form.name) { return null; }

      return this.form.name.length > 1;
    },
    validEmail() {
      if (!this.form.email) { return null; }

      return validate_email(this.form.email);
    },
    validPhone() {
      if (!this.form.phone) { return null; }

      return this.validate_phone(this.form.phone);
    },
    validOrgNumber() {
      if (!this.form.org_number) { return null; }

      if (this.form.org_number.length === 10) { return true; }

      return false;
    },

    validPersonnr() {
      if (!this.form.personnr) { return null; }

      const formatted = validate_pers_number(this.form.personnr);

      return formatted.valid;
    },
    validZipcode() {

      if (!this.form.zipcode) { return null; }

      return !!this.form.zipcode && !isNaN(parseInt(this.form.zipcode)) && this.form.zipcode.length > 4;
    },
    validAddress() {
      if (!this.form.address) { return null; }

      return !!this.form && !!this.form.address && this.form.address.length > 3;
    },
    validPost() {
      if (!this.form.post) { return null; }

      return !!this.form && !!this.form.post && this.form.post.length > 1;
    },
    validAllAddress() {
      const v1 = this.notNullAndTrue(this.validZipcode);
      const v2 = this.notNullAndTrue(this.validAddress);
      const v3 = this.notNullAndTrue(this.validPost);

      return v1 && v2 && v3;
    },

    allIsValid() {
      const v1 = this.notNullAndTrue(this.validEmail);
      const v2 = this.notNullAndTrue(this.validPhone);
      const v3 = this.is_org ? this.notNullAndTrue(this.validOrgNumber) : this.notNullAndTrue(this.validPersonnr);
      const v4 = this.notNullAndTrue(this.validAllAddress);

      return v1 && v2 && v3 && v4;
    }
  },
  watch: {
    item(newValue) {
      this.form = this.item;
    },
    'form.zipcode'() {
      this.$emit('change', this.form);
    },
    'form.address'() {
      this.$emit('change', this.form);
    },
    'form.phone'() {
      this.$emit('change', this.form);
    },
    'form.email'() {
      this.$emit('change', this.form);
    },
    'form.post'() {
      this.$emit('change', this.form);
    },
    'form.personnr'() {
      this.$emit('change', this.form);
    }
  },
  data() {
    return {
      is_org: false,
      form: {
        org_number: null,
        name: null,
        phone: null,
        zipcode: null,
        post: null,
        email: null,
        personnr: null,
        id: null
      }
    };
  },
  mounted() {

  },
  methods: {
    notNullAndTrue(val) {
      if (val === null || val === undefined) { return false; }
      return val;
    },
    getForm() {
      return this.form;
    },
    validateAll() {
      const v1 = this.notNullAndTrue(this.validEmail);
      const v2 = this.notNullAndTrue(this.validPhone);
      const v3 = this.is_org ? this.notNullAndTrue(this.validOrgNumber) : this.notNullAndTrue(this.validPersonnr);
      const v4 = this.notNullAndTrue(this.validAllAddress);

      return v1 && v2 && v3 && v4;
    },

    validate_phone(phone) {
      if ((phone+'').length < 6) {
        return false;
      }

      var re = /^\+?\d*$/;
      return re.test(String(phone).toLowerCase());
    },

    delete_row_clicked() {
      this.$emit('deleteMemberClicked', this.item.id);
    },

  }
};
</script>
