<template>
  <div>
    <b-card>
      
        <p>Du är inloggad som <strong>{{ currentMember.email }}</strong></p>
        <!--<p>Adress: {{ currentMember.address }}</p>
        <p>Postort: {{ currentMember.zipcode }} {{ currentMember.post }}</p>-->
        <p><a href="/profile">Gå till profil >></a></p>
      
    </b-card>
  </div>
</template>


<style lang="scss" scoped>

</style>

<script>

import { mapGetters } from 'vuex';

export default {
  name: 'small-member-loggedin-info',
  props: [],
  components: {
    
  },
  computed: {
    ...mapGetters(['currentMember']),
  },
  watch: {
      
  },
  data() {
    return {
      
    };
  },
  mounted() {
    
  },
  methods: {

  }
};
</script>
