<template>
  <div class="card card-custom ">

    <!--begin::Header-->
    <div class="card-header border-0 py-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark">&nbsp;</span>
      </h3>
      <div class="card-toolbar">
          <a href="#" class="btn btn-primary font-weight-bolder font-size-sm" @click.prevent="createEventMember"
            ><i class="menu-icon flaticon2-plus" style="font-size: 1.0em;"></i>Lägg till deltagare</a>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->

    <div class="card-body pt-0 table-responsive">

      <div class="table-responsive">
        <table
          class="table table-head-custom table-vertical-center table-head-bg table-borderless"
        >
          <thead>
            <tr class="text-left">
              <th style="min-width: 40px" class="pl-7">
                <span class="text-dark-75">Deltagare</span>
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(item, i) in members">
              <MemberToEventListItem
                v-bind:key="i"
                :item="item"
                @change="onMemberChanged"
                @deleteMemberClicked="deleteMemberClicked"
                @toastr="toastr"
                />
            </template>
          </tbody>
        </table>
      </div>

    </div>

    <!--end::Body-->
  </div>
</template>


<style lang="scss" scoped>


</style>

<script>
import MemberToEventListItem from '@/view/pages/members/public/event_category_list/MemberToEventListItem.vue';
import { validate_email } from '@/core/services/email';
import validate_pers_number from '@/core/services/personnr';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'event-selectable-box',
  props: [],
  emits: ['onValidationMembers'],
  mixins: [ toasts ],
  components: {
    MemberToEventListItem
  },
  computed: {

  },
  watch: {

  },
  data() {
    return {
      members: [ { id: 0 } ]
    };
  },
  mounted() {

  },
  methods: {
    onMemberChanged(member) {
      const index = this.members.findIndex(item => item.id === member.id);
      this.members[index] = member;
      // Vue3 compatability for Vue.set
      this.members = [...this.members];

      this.validateAllMembers();
    },
    validateAllMembers() {
      if (this.members.length === 0) {
        this.$emit('onValidationMembers', null, false);
        return;
      }

      for (const m of this.members) {
        const valid = this.allIsValid(m);

        if (!valid) {
          this.$emit('onValidationMembers', null, false);
          return;
        }
      }

      this.$emit('onValidationMembers', this.members, true);
    },


    allIsValid(member) {
      const v1 = this.notNullAndTrue(this.validPhone(member.phone));
      const v2 = this.notNullAndTrue(this.validEmail(member.email));
      //const v3 = this.notNullAndTrue(this.validatePersonnr(member.personnr));
      const v4 = this.notNullAndTrue(this.validAllAddress(member.address, member.zipcode, member.post));

      return v1 && v2 && v4;
    },

    validZipcode(zipcode) {

      if (!zipcode) { return null; }

      return !!zipcode && !isNaN(parseInt(zipcode)) && zipcode.length > 4;
    },
    validAddress(address) {
      if (!address) { return null; }

      return !!address && address.length > 3;
    },
    validPost(post) {
      if (!post) { return null; }

      return !!post && post.length > 1;
    },
    validAllAddress(address, zipcode, post) {
      const v1 = this.notNullAndTrue(this.validZipcode(zipcode));
      const v2 = this.notNullAndTrue(this.validAddress(address));
      const v3 = this.notNullAndTrue(this.validPost(post));

      return v1 && v2 && v3;
    },

    validPhone(phone) {
      if (!phone) { return null; }

      if ((phone+'').length < 6) {
        return false;
      }

      var re = /^\+?\d*$/;
      return re.test(String(phone).toLowerCase());
    },
    validEmail(email) {
      if (!email) { return null; }

      return validate_email(email);
    },
    validatePersonnr(personnr) {
      if (!personnr) { return null; }

      const formatted = validate_pers_number(personnr);

      return formatted.valid;
    },

    notNullAndTrue(val) {
      if (val === null || val === undefined) { return false; }
      return val;
    },

    createEventMember() {

      let id = Math.floor(Math.random() * 9999999);

      let found = this.members.findIndex(item => item.id === id);

      while (found !== -1) {
        id = Math.floor(Math.random() * 9999999);
        found = this.members.findIndex(item => item.id === id);
      }

      this.members.push({
        id: id,
        personnr: null,
        email: null
      });

      this.validateAllMembers();
    },
    deleteMemberClicked(id) {

      this.members = this.members.filter(item => item.id !== id);
      this.validateAllMembers();
    },

  }
};
</script>
