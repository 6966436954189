<template>
  <div>
      <b-row>
        <b-col>
          <label class="kt-option kt-option kt-option--plain" style="margin-top: 12px;">
          <span class="kt-option__control">
            <label v-if="has_tickets > 0" class="radio radio-outline radio-success mr-5">
              <input
                type="radio"
                :checked="isChecked"
                :value="item.event_id"
                name="radio_event_id"
                @change="$emit('change', $event.target.value)"
              />
              <span class="mr-2"></span>
            </label>
          </span>
          <span class="kt-option__label">
            <span class="kt-option__head">
              <span class="kt-option__title">
                {{ item.name }}
              </span>
            </span>
            <span class="kt-option__body">
              <p style="margin-bottom: 2px;">{{ item.location_descr }}</p>
            </span>
            <span v-if="!has_tickets" style="font-weight: bold; color: red;" class="mt-4">Fel: Biljett saknas</span>
            <span v-else style="font-weight: bold;" class="mt-4">{{ item.shop_items[0].shop_item.price_sek }} {{item.shop_items[0].shop_item.currency}}</span>
          </span>
        </label>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Från datum" label-for="person-personnr">
              <b-form-input autocomplete="off"
              id="to_datetime"
              v-model="format_from_datetime"
              placeholder=""
              :disabled="true"
              />
          </b-form-group>
        </b-col>
        <b-col>


          <b-form-group label="TIll datum" label-for="to_datetime">
              <b-form-input autocomplete="off"
              id="to_datetime"
              v-model="format_to_datetime"
              placeholder=""
              :disabled="true"
              />
          </b-form-group>

        </b-col>
      </b-row>


      <hr/>

  </div>
</template>


<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_memlist_checkbox.scss";

.date-box {
  border: 1px solid #dedede;
  border-radius: 8px;
  float: left;
  width: 146px;
  padding: 4px;
  height: 36px;
}

.date-box span {
  margin-right: 10px;
}



.date-box::after {
  clear: both;
}

.date-box * {
  float: left;
  margin-top: 6px;
}

.date-box p {
  margin-left: 10px;
  font-weight: bold;
}


hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.card-body {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}


.wizard-body {
  max-width: 1000px;
  width: 100%;
}

.kt-option {
  display: flex;
  padding: 1.4em;
  border: 1px solid #ebedf2;
  border-radius: 4px;
}
.kt-option .kt-option__control {
  width: 2.7rem;
  padding-top: 0.1rem;
}
.kt-option .kt-option__control .kt-radio {
  display: block;
}
.kt-option .kt-option__label {
  width: 100%;
}
.kt-option .kt-option__label .kt-option__head {
  display: flex;
  justify-content: space-between;
}
.kt-option .kt-option__label .kt-option__head .kt-option__title {
  font-size: 1.1rem;
  font-weight: 400;
  color: #595d6e;
}
.kt-option .kt-option__label .kt-option__head .kt-option__focus {
  font-size: 1.1rem;
  font-weight: 500;
  color: #48465b;
}
.kt-option .kt-option__label .kt-option__body {
  display: block;
  padding-top: 0.7rem;
  font-size: 0.9rem;
  color: #74788d;
}
.kt-option.kt-option--plain {
  border: 0;
  padding: 0;
  margin-top: -0.2rem;
  margin-bottom: 2rem;
  align-items: center;
}
.kt-option.kt-option--plain .kt-option__control {
  vertical-align: middle;
  width: 2.7rem;
}
.kt-option.kt-option--plain .kt-option__control .kt-radio {
  margin-bottom: 1.6rem;
}
.kt-option.kt-option--plain .kt-option__label .kt-option__body {
  padding-top: 0.2rem;
}
.is-valid .kt-option {
  border-color: #1dc9b7;
}
.is-invalid .kt-option {
  border-color: #fd397a;
}

.has-success .kt-option .kt-option__label .kt-option__head .kt-option__title {
  color: #1dc9b7;
}

.has-error .kt-option .kt-option__label .kt-option__head .kt-option__title {
  color: #ffb822;
}
</style>

<script>

import dayjs from 'dayjs';

export default {
  name: 'event-selectable-box',
  props: ['item'],
  emits: ['change'],
  components: {

  },
  computed: {
    format_from_datetime() {
      if (!this.item || !this.item.from_datetime) {
        return 'Saknas';
      }

      return this.format_date(this.item.from_datetime);
    },
    format_to_datetime() {
      if (!this.item || !this.item.to_datetime) {
        return 'Saknas';
      }

      return this.format_date(this.item.to_datetime);
    }
  },
  watch: {

  },
  data() {
    return {
      has_error: false,
      has_tickets: false,
      cost_sek: 100,
      isChecked: false,
      value: 0,
      descr: 'this is the description',
      title: 'this is the title',
      name: 'this is the name',
    };
  },
  mounted() {
    this.has_error = this.item.shop_items.length === 0;
    this.has_tickets = this.item.shop_items.length > 0;
  },
  methods: {
    format_date(dt) {
      return dayjs(dt).format('YYYY-MM-DD');
    },
    onSelectEvent() {}
  }
};
</script>
