<template>
  <tr style="border-bottom: 1px solid #dedede;">
    <td>
      <b-row>
        <b-col>
          <b-form-input autocomplete="off"
            id="person-namn"
            v-model="form.name"
            placeholder="Fullständigt namn"
            :state="validName"
          />

        </b-col>
      </b-row>


      <b-row class="mt-4">
        <b-col>
          <b-form-input autocomplete="off"
            id="person-personnr"
            v-model="form.personnr"
            placeholder="Personnummer"
            :state="validatePersonnr"
          />

        </b-col>
      </b-row>

      <b-row class="mt-4">
        <b-col>

          <b-form-input autocomplete="off"
            id="person-email"
            v-model="form.email"
            placeholder="Epost"
            :state="validEmail"
          />
        </b-col>
      </b-row>

      <b-row class="mt-4">
        <b-col>

          <b-form-input autocomplete="off"
            id="person-phone"
            v-model="form.phone"
            placeholder="Telefon"
            :state="validPhone"
          />
        </b-col>
      </b-row>

      <b-row class="mt-4">
        <b-col>
          <b-form-input autocomplete="off"
            id="person-address"
            v-model="form.address"
            placeholder="Adress"
            :state="validAddress"
          />
        </b-col>
      </b-row>


      <b-row class="mt-4">
        <b-col cols="12" lg="4">
          <b-form-input autocomplete="off"
            id="person-zipcode"
            v-model="form.zipcode"
            placeholder="Postnr"
            :state="validZipcode"
          />
        </b-col>
        <b-col cols="12" lg="8">
          <b-form-input autocomplete="off"
            id="person-post"
            v-model="form.post"
            placeholder="Ort"
            :state="validPost"
          />
        </b-col>
      </b-row>

      <b-row v-if="!allIsValid">
        <b-col>
          <p style="margin-top: 8px; color: red;">Fyll i uppgifterna innan du fortsätter</p>
        </b-col>
      </b-row>
    </td>
    <td class="text-right pr-0 mt-4 align-top">

      <a class="btn btn-icon btn-light btn-sm" @click="delete_row_clicked()">
        <span class="svg-icon svg-icon-md svg-icon-primary">
          <!--begin::Svg Icon-->
          <inline-svg src="/assets/svg/Trash.svg"></inline-svg>
          <!--end::Svg Icon-->
        </span>
      </a>
    </td>
  </tr>
</template>


<style lang="scss" scoped>

</style>

<script>

import { validate_email } from '@/core/services/email';
import validate_pers_number from '@/core/services/personnr';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'event-member-list-item',
  props: ['item'],
  emits: ['change', 'deleteMemberClicked'],
  components: {

  },
  mixins: [ toasts ],
  computed: {
    validName() {
      if (!this.form.name) { return null; }

      return this.form.name.length > 1;
    },
    validPhone() {
      if (!this.form.phone) { return null; }

      if ((this.form.phone+'').length < 6) {
        return false;
      }

      var re = /^\+?\d*$/;
      return re.test(String(this.form.phone).toLowerCase());
    },
    validEmail() {
      if (!this.form.email) { return null; }

      return validate_email(this.form.email);
    },
    validatePersonnr() {
      if (!this.form.personnr) { return null; }

      const formatted = validate_pers_number(this.form.personnr);

      return formatted.valid;
    },
    allIsValid() {
      const v1 = this.notNullAndTrue(this.validPhone);
      const v2 = this.notNullAndTrue(this.validEmail);
      //const v3 = this.notNullAndTrue(this.validatePersonnr);
      const v4 = this.notNullAndTrue(this.validAllAddress);

      return v1 && v2 && v4;
    },

    validZipcode() {

      if (!this.form.zipcode) { return null; }

      return !!this.form.zipcode && !isNaN(parseInt(this.form.zipcode)) && this.form.zipcode.length > 4;
    },
    validAddress() {
      if (!this.form.address) { return null; }

      return !!this.form && !!this.form.address && this.form.address.length > 3;
    },
    validPost() {
      if (!this.form.post) { return null; }

      return !!this.form && !!this.form.post && this.form.post.length > 1;
    },
    validAllAddress() {
      const v1 = this.notNullAndTrue(this.validZipcode);
      const v2 = this.notNullAndTrue(this.validAddress);
      const v3 = this.notNullAndTrue(this.validPost);

      return v1 && v2 && v3;
    },

  },
  watch: {
    item(newValue) {
      this.form = this.item;
    },
    'form.email'() {
      this.form.id = this.item.id;
      this.$emit('change', this.form);
    },
    'form.personnr'() {
      this.form.id = this.item.id;
      this.$emit('change', this.form);
    },
    'form.phone'() {
      this.form.id = this.item.id;
      this.$emit('change', this.form);
    },
    'form.zipcode'() {
      this.form.id = this.item.id;
      this.$emit('change', this.form);
    },
    'form.address'() {
      this.form.id = this.item.id;
      this.$emit('change', this.form);
    },
    'form.post'() {
      this.form.id = this.item.id;
      this.$emit('change', this.form);
    }
  },
  data() {
    return {
      form: {
        email: null,
        personnr: null,
        id: null
      }
    };
  },
  mounted() {

  },
  methods: {
    notNullAndTrue(val) {
      if (val === null || val === undefined) { return false; }
      return val;
    },
    delete_row_clicked() {
      this.$emit('deleteMemberClicked', this.item.id);
    },

  }
};
</script>
