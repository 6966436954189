<template>
  <div class="tacbox">
    <input
      id="checkbox"
      type="checkbox"
      @change="$emit('selected', term_id, $event.target.checked)"
      />
    <span>{{is_mandatory ? '(Obligatoriskt)' : ''}} {{ pretext }} <a href="#" @click="showTerms">{{ linktext }}</a>{{ posttext }}</span>

  </div>
</template>

<style scoped>
.tacbox {
  display: inline-block;
  padding: 1em;

  border: 1px solid #ddd;
  background-color: #eee;
  width: 100%;

}

input {
  height: 2em;
  width: 2em;
  vertical-align: middle;
  float: left;
}

.tacbox span {
  display: block;
  overflow: auto;
  margin-left: 8px;
  padding-left: 8px;
  padding-top: 2px;
}

</style>

<script>
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'terms-and-conditions-checkbox',
  props: ['text','term_id','is_mandatory'],
  emits: ['selected', 'showTerms'],
  components: {},
  data() {
    return {
      isChecked: false,
      pretext: '',
      posttext: '',
      linktext: ''
    };
  },
  mixins: [ toasts ],
  watch: {
    isChecked(newValue, oldValue) {
      this.$emit('selected', this.term_id, newValue);
    },
  },
  mounted() {

    var parts = this.text.split('%');

    if (parts.length < 2) {
      this.linktext = this.text;
      return;
    }

    if (parts.length === 2) {
      this.pretext = parts[0];
      this.linktext = parts[1];
      return;
    }

    this.pretext = parts[0];
    this.linktext = parts[1];
    this.posttext = parts[2];
  },

  methods: {
    showTerms() {
      this.$emit('showTerms', this.term_id);
    },

  }
};
</script>
